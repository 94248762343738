// --------------------------------------------------
// Footer
// --------------------------------------------------

// Footer container
.footer {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 220px;
  padding: 25px 35px;

  background: #FFF;

  @media screen and (max-width: 767px) {
    flex-direction: column;
    height: auto;
    padding-top: 35px;
    padding-bottom: 35px;
  }
}

.footer-logo {
  margin-bottom: 20px;
  width: 100%;

  @media(max-width: 1075px) {
    border-top: 2px solid #E5E5E5;
    padding-top: 40px;
    margin-top: 50px;
  }

  img {
    display: block;
    min-width: 261px;
    margin: 0 auto;
  }
}

// Reset Footer list styles
.footer-list {
  display: flex;
  padding: 0;
  margin: 0;
  list-style-type: none;

  li{
    display: inline-block;
  }
}

// List of socials icons
.footer-socials {
  width: auto;

  @media screen and (max-width: 767px) {
    order: 2;
  }

  li {
    display: inline-block;
    padding-right: 15px;
  }

  @media screen and (max-width: 1359px) {
    li {
      padding-right: 10px;
    }
  }
}

// List of logos
.footer-logos {

  li {
    padding-left: 25px;
  }

  img {
    display: block;
    width: auto;
    height: 54px;
  }

  @media screen and (max-width: 1359px) {

    li {
      padding-left: 15px;
    }

    img {
      height: 40px;
    }
  }

  @media screen and (max-width: 767px) {
    order: 1;
    display: block;

    li {
      display: block;
      width: 100%;
      padding: 0 0 25px 0;
    }

    img {
      display: block;
      width: 100%;
      max-width: 125px;
      height: auto;
      margin: 0 auto;
    }
  }
}

// Text zone
.footer-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: $fontLato;
  color: #575757;
  font-size: 14px;
  font-weight: 400;
  text-align: left;

  @media screen and (max-width: 1359px) {
    font-size: 13px;
  }

  @media screen and (max-width: 767px) {
    order: 3;
  }

  p {
    display: inline-block;
    padding: 0 15px;

    @media screen and (max-width: 1359px) {
      padding: 0 10px;
    }
    @media screen and (max-width: 904px) {
      display: block;
      line-height: 1;
      margin: 5px 0;
    }

    @media screen and (max-width: 767px) {
      text-align: center;
      margin-top: 25px;
    }
  }

  a {
    display: inline-block;
    text-decoration: none;
    color: inherit;
  }
}
